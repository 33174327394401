<template>
  <div class="container-box">
    <div class="main-box">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item><router-link to="/">首页</router-link></a-breadcrumb-item>
        <!-- <a-breadcrumb-item>新知共享</a-breadcrumb-item> -->
        <a-breadcrumb-item style="color:#15B7DD;">详情</a-breadcrumb-item>
      </a-breadcrumb>

      <div class="content-box">
        <div class="content-left">
          <div class="header">
            <div class="title"><p>{{data.name}}</p></div>
            <div class="brief">
              <!-- <div class="brief-time">作者：{{data.author}}</div>
              <div class="brief-time">译者：{{data.translator}}</div> -->
              <div class="brief-time">创建时间：{{createtime(data.createTime)}}</div>
            </div>
          </div> 

          <div v-if="data.fileUrl" class="footer">
             <!-- <a target="_blank" href="https://hxyuandao.com/uploaded/file/202111/11/%E8%AF%BE%E5%A4%96%E9%98%85%E8%AF%BB2021%E5%B9%B411%E6%9C%88%E5%B0%81%E9%9D%A25.pdf">1111111111</a> -->
            <!-- <iframe :src="data.fileUrl +'#view=FitH,top&toolbar=0'" width="100%" height="1292"> -->
            <iframe :src="data.fileUrl" width="100%" height="1292">
            <!-- 此浏览器不支持PDF文件预览。 请下载后查看: <a href="https://hxyuandao.com/uploaded/file/202111/11/%E8%AF%BE%E5%A4%96%E9%98%85%E8%AF%BB2021%E5%B9%B411%E6%9C%88%E5%B0%81%E9%9D%A25.pdf">下载 PDF</a> -->
           </iframe>
          </div>
        </div>

        <div class="content-right">
          <div class="hot-title"></div>
          <div class="title-box">
            <div class="line"></div>同系列文档
          </div>
          <div class="list">
            <a-carousel effect="fade" autoplay :autoplaySpeed="8000" :key="carouselKey">
              <div v-for="(i, n) in voiceList" :key="n" class="sublist">
                <div v-for="(item, index) in i" :key="index" class="sub" @click="toDetail(item)">
                  <img class="sub-banner"  :src="item.img" alt="" />
                  <div class="sub-info">
                    <div class="sub-title mallHidden">{{item.name}}</div>
                    <!-- <div class="sub-author">作者：{{item.author}}</div>
                    <div class="sub-translator">译者：{{item.translator}}</div> -->
                  </div>
                </div>
              </div>
            </a-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      data: [],
      voiceList: [],
      pages: 0,
      carouselKey: new Date().getTime()
      // pdfUrl:'https://hxyuandao.com/uploaded/file/202111/11/%E8%AF%BE%E5%A4%96%E9%98%85%E8%AF%BB2021%E5%B9%B411%E6%9C%88%E5%B0%81%E9%9D%A25.pdf'
    }
  },
  // 事件处理器
  methods: {
    repair0(m) {
      return m < 10 ? '0' + m : m
    },
    createtime(times) {
      var time = new Date(times);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return y + '-' + this.repair0(m) + '-' + this.repair0(d) + ' ' + this.repair0(h) + ':' + this.repair0(mm) +
        ':' + this.repair0(s);
    },

    toDetail(x){
      let lastIndex = x.fileUrl.lastIndexOf('.');
      let suffix = x.fileUrl.substring(lastIndex+1)
      if(suffix.toLowerCase() == 'epub'){
        this.$router.push(
          "/ebookDetail?url=" +
            this.$AES.encode_data(String(x.fileUrl))
        );
      } else {
        this.$router.push(
          "/voiceDetail?autismId=" +
            this.$AES.encode_data(String(x.autismId)) +
            "&vtypeID=" +
            this.$AES.encode_data(String(this.vtypeID))
        );
      }
    },

    getDetail(id){
      this.$ajax({
        url: '/hxclass-pc/autism/details',
        params: {
          autismId: id,
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.data = res.data;
          this.data.fileUrl = this.data.fileUrl + '?ci-process=doc-preview&dstType=html&copyable=0&htmlParams=eyJjb21tb25PcHRpb25zIjp7ImlzU2hvd1RvcEFyZWEiOmZhbHNlLCJpc1Nob3dIZWFkZXIiOmZhbHNlLCJpc0lmcmFtZVZpZXdGdWxsc2NyZWVuIjp0cnVlfSwid29yZE9wdGlvbnMiOnsiaXNTaG93RG9jTWFwIjp0cnVlfSwicGRmT3B0aW9ucyI6eyJpc0luU2FmZU1vZGUiOnRydWUsImlzU2hvd0NvbW1lbnQiOmZhbHNlfX0=';
          // {
          //   "commonOptions":{
          //     "isShowTopArea":false,
          //     "isShowHeader":false,
          //     "isIframeViewFullscreen":true
          //   },
          //   "wordOptions":{
          //     "isShowDocMap":true
          //   },
          //   "pdfOptions":{
          //     "isInSafeMode":true,
          //     "isShowBottomStatusBar":false,
          //     "isShowComment":false
          //   }
          // }
        }
      })
    },

    getVoice(){
      this.$ajax({
        url: '/hxclass-pc/autism/list',
        params: {
          autismClassificationId: this.vtypeID,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.pages = Math.ceil(res.data.length/6);
          this.carouselKey = new Date().getTime()
          let i = -1,n = 0;
          res.data.forEach((item,index)=> {
            if(item.autismId != this.id){
              if(n%6==0){
                i ++;
                this.voiceList[i] = [];
              }
              this.voiceList[i].push(item);
              n ++;
            }
          });;
        }
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.id = this.$AES.decode_data(this.$route.query.autismId);
    this.vtypeID = this.$route.query.vtypeID ? this.$AES.decode_data(this.$route.query.vtypeID) : null;
    this.getDetail(this.id);
    this.getVoice();
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.$emit('setIndex', false);
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    $route(){
      this.id = this.$AES.decode_data(this.$route.query.autismId);
      // this.vtypeID = this.$AES.decode_data(this.$route.query.vtypeID);
      this.getDetail(this.id);
      this.getVoice();
    }
  }
}
</script>

<style lang="less" scoped>
.container-box{
  .main-box{
    padding-top: 22px;
    padding-bottom: 60px;
    .content-box{
      margin-top: 22px;
      width: 100%;
      overflow: hidden;

      .content-left{
        float: left;
        width: 950px;
        .header{
          padding: 30px;
          background-color: #FFF;
          .title{
            margin-bottom: 30px;
            p{
              line-height: 40px;
              font-family: PingFangMedium;
              font-size: 32px;
              font-weight: bold;
              color: @text-color;
            }
          }
          .brief{
            display: flex;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular,   Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            line-height: 21px;
            letter-spacing: 1px;
            .brief-time{
              margin-right: 60px;
            }
          }
        }

        .footer{
          margin-top: 22px;
        }
      }

      .content-right{
        float: right;
        padding: 36px 30px 48px;
        width: 430px;
        background-color: #FFF;

        .title-box{
          padding-bottom: 31px;
          border-bottom: 1px solid #F2F2F2;
          font-size: 24px;
          font-family: Source Han Sans CN-Medium,   Source Han Sans CN;
          font-weight: 500;
          color: #15B7DD;
          line-height: 28px;
          .line{
            display: inline-block;
            margin-right: 8px;
            width: 4px;
            height: 19px;
            background: #15B7DD;
            border-radius: 2px 2px 2px 2px;
            vertical-align: middle;
          }
        }

        .list{
          .sublist{
            display: flex!important;;
            flex-wrap: wrap;
            justify-content: space-between;
            .sub{
              position: relative;
              margin-top: 30px;
              .sub-banner{
                width: 175px;
                height: 227px;
                border-radius: 5px;
              }
              .sub-info{
                position: absolute;
                left: 0;
                bottom: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding: 0 9px;
                width: 175px;
                height: 62px;
                // background: rgba(0, 0, 0, 0.2);
                background: #3189C9;
                border-radius: 0px 0px 5px 5px;
                font-size: 14px;
                font-family: PingFangMedium;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 21px;
                .sub-title{
                  text-align: justify;
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 24px;
                }
              }
            }
            
          }

          /deep/.ant-carousel .slick-dots-bottom {
              bottom: -20px;
          }
          /deep/.ant-carousel .slick-dots li button {
            width: 12px;
            height: 12px;
            background: #ada9a9;
            border-radius: 50%;
          }

          /deep/.ant-carousel .slick-dots li {
            margin: 0 8px;
          }
          /deep/.slick-active button{
            background: #15B7DD !important;
          }
        }
      }
    }
  }
}

// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .container-box {
    .main-box {
      padding-top: 17px;
      .content-box {
        margin-top: 17px;
        .content-left{
          width: 820px;
          .header{
            .title{
              p{
                line-height: 32px;
                font-size: 24px;
              }
            }
          }
        }
        .content-right{
          width: 360px;
          padding: 30px 30px 42px;
          .title-box{
            font-size: 20px;
            font-weight: bold;
            padding-bottom: 20px;
          }
          .list {
            .sublist {
              .sub {
                margin-top: 24px;
                .sub-banner{
                  width: 140px;
                  height: 182px;
                }
                .sub-info{
                  width: 140px;
                  .sub-title{
                    font-size: 14px;
                    line-height: 22px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
